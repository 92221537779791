@import '../variables';

.home {
  &-container {
    background-color: $primary-color;

    main {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &-body {
    padding: $body-padding;
    padding-top: 50px;
    max-width: 1024px;
  }
}
