@import '../variables';

.introduction {
  margin-top: 5rem;

  h1 {
    font-size: 3rem;
    font-weight: 400;
    margin-bottom: 0px;
  }

  p {
    span {
      color: $text-highlight-color;
      font-size: 2rem;
    }
  }
}
