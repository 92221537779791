@import './variables';

html {
  font-size: 10px;
}

body {
  font-family: 'Preahvihear', sans-serif;
  color: white;
  height: 100%;
}

h1 {
  font-size: 2.5rem;
  font-weight: 400;
}

p {
  font-size: 1.6rem;
}

.stars {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  display: block;
  opacity: 30%;
}

.stars {
  background: $primary-color
    url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top
    center;
  z-index: 0;
}
