@import '../variables';
@import '../mixins';

.hero-header {
  &-container {
    width: 100%;
  }

  &__name {
    position: relative;
    margin-bottom: 7rem;
    display: flex;
    font-size: 1.5rem;

    img {
      position: absolute;
      top: 0;
      left: 17rem;
    }

    p {
      position: absolute;
      left: 11rem + 17rem;
      top: 0.7rem;

      span {
        color: $text-highlight-color;
      }
    }
  }

  &__detail {
    display: flex;
    gap: 5rem;
    align-items: center;
    flex-wrap: wrap;

    &-avartar {
      position: relative;
      width: 30rem; /* Adjust as needed */
      height: 30rem; /* Adjust as needed */
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 50%; /* Optional: makes it circular */

      img {
        object-fit: cover;
        position: absolute;
        z-index: 1;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 150%; /* Start small */
        height: 150%;
        background: radial-gradient(
          circle,
          rgba(255, 255, 255, 0.8) 0%,
          rgba(255, 255, 255, 0.4) 30%,
          rgba(128, 0, 128, 0.2) 60%,
          rgba(0, 0, 0, 0) 100%
        );
        transform: translate(-50%, -50%) scale(0);
        border-radius: 50%;
        z-index: 0;
        animation: shine 3s infinite;
        filter: blur(3rem); /* Add a soft blur effect */
      }
    }

    &-cover {
      max-width: 30rem;
    }

    @include respond-to(md) {
      justify-content: center;

      &-cover {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 50rem;

        h1 {
          text-align: center;
        }
      }
    }
  }
}

@keyframes shine {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0.6;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.8;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}
