@import '../variables';

.footer {
  background-color: $text-highlight-color;
  border-top: 2rem solid $text-highlight-color;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 3rem;

  &-contacts {
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    justify-content: center;

    &__icon {
      display: flex;
      align-items: center;
      gap: 2rem;

      & > img {
        width: 4rem;
      }

      span {
        font-size: 1.7rem;
      }

      a {
        img {
          height: 10rem;
        }
      }
    }
  }
}
