@import '../../variables';

.experience-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  color: black;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
  }

  &__content {
    position: relative;
    background: white;
    margin: 2rem;
    font-size: 1.4rem;
    max-height: 60vh;
    padding: 20px;
    border-radius: 8px;
    overflow-y: auto;
    -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    &--time {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;

      p {
        color: $text-highlight-color;
        font-weight: bold;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: center;

    h1 {
      display: flex;
      gap: 2rem;
      font-size: 4rem;
      align-items: center;

      img {
        height: 12rem;
      }
    }
  }

  &__close {
    background: none;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    margin: 1rem;
    font-size: 1.5rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50px;
    border: 3px solid $text-highlight-color;
    cursor: pointer;
    background-color: white;

    &--icon {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &__body {
    margin-bottom: 16px;
  }

  &__pages {
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  &__page {
    display: flex;

    &-content {
      padding: 20px;
      box-sizing: border-box;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
}

.pagination-dot {
  width: 12px;
  height: 12px;
  margin: 0 8px;
  border-radius: 50%;
  background: gray;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
}

.pagination-dot.active {
  background: orange; /* Highlight active dot */
}
