@import '../variables';

.header {
  &-container {
    background-color: $header-color;
    height: 8rem;
    padding: $body-padding;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-menu {
    display: flex;
    gap: 3rem;
    font-size: 1.4rem;

    a {
      text-decoration: none;
      color: white;
    }
  }

  &-logo {
    height: 10rem;
    display: contents;

    img {
      height: 70%;
    }
  }
}
